<template>
    <div v-if="Object.hasOwnProperty.call(question,'type')" class="right_content">
        <div v-for="(item, index) in questionsInfo" :key="index" class="each-question-content">
            <Question
                v-if="item.list.length > 0"
                :data="item"
                :sort="index"
            />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Question from './question'
export default {
    components:{
        Question
    },
    data() {
        return {
        }
    },
    computed:{
        ...mapState({
            questionsInfo:(state) => state.questionsInfo,
            question:(state) => state.question,
            examAnswers:(state) => state.answers,
            marks:(state) => state.marks,
            answers:(state) => {
                let arr = [];
                const id = state.question.id;
                if(Object.hasOwnProperty.call(state.answers,id)){
                    arr = state.answers[id];
                }
                console.log('答案',arr);
                return arr;
            }
        })
    },
    mounted() {
        console.log(this.question);
    },
    methods: {
        
    },
}
</script>
<style lang="scss" scoped>
.right_content{
    width:calc(100% - 360px);
    height:100%;
    position: relative;
    background: #fff;
    overflow-y: auto;
}
</style>
