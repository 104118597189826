<template>
    <div class="question">
        <div class="title-box">
            <span class="question-title">
                {{
                    sort === 0 ? '一、' : (
                        sort === 1 ? '二、' : (
                            sort === 2 ? '三、' : (
                                sort === 3 ? '四、' : (
                                    sort === 4 ? '五、' : '' ))))
                }}
                {{ data.list[0].type | formatQuestionType }}（{{ data.list.length }}）
            </span>
            <i :class="['iconfont', questionShow ? 'safety-icon-arrow_up': 'safety-icon-arrow_down']" @click="toggleShow" />
            <span class="each-score">每题{{ Number(data.list[0].score) }}分</span>
        </div>
        <div v-if="questionShow" class="content-box">
            <div v-for="(item, index) in data.list" :key="index" class="item-question">
                <div class="question-and-options">
                    <div class="each-question-title">
                        {{ index + 1 }}.{{ item.name }}
                    </div>
                    <div v-if="Number(item.type)+1 === 2" class="checkbox-question question-answers">
                        <div v-for="(tmps, key) in chunkArray(item.options)" :key="key">
                            <div class="options">
                                <div class="answer">
                                    <span :class="['option-container',
                                                   hasSelect(item,tmps.option)]"
                                    >{{ tmps.option }}</span>
                                    <span class="optionContent">{{ tmps.option_content }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else-if="Number(item.type)+1 === 1 || Number(item.type)+1 === 3"
                        :class="
                            [
                                'question-answers',
                                { 'radio-question': Number(item.type)+1 === 1 },
                                { 'judge-question': Number(item.type)+1 === 3 },
                            ]"
                    >
                        <div v-for="(tmps, key) in chunkArray(item.options)" :key="key">
                            <div class="options">
                                <div class="answer">
                                    <span :class="['option-container',
                                                   hasSelect(item,tmps.option)]"
                                    >{{ tmps.option }}</span>
                                    <span class="optionContent">{{ tmps.option_content }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else-if="Number(item.type)+1 === 4 || Number(item.type)+1 === 5"
                        :class="
                            [
                                'question-answers',
                                { 'fill-blanks-question': Number(item.type)+1 === 4 },
                                { 'subjective-question': Number(item.type)+1 === 5 }
                            ]"
                    >
                        答：{{ getAnswer(item) }}
                    </div>
                </div>
                <div class="result-box">
                    <div class="result">
                        <div v-if="Number(item.is_correct) === 1" class="current">
                            回答正确!
                        </div>
                        <div v-else-if="Number(item.is_correct) !== 1" class="error">
                            <div>回答错误!</div>
                            <div class="current-answer">
                                正确答案：{{ currentAnswer(item) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components:{
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        sort: {
            type: Number,
            default: () => 0,
        },
    },
    data() {
        return {
            questionShow: true,
        };
    },
    methods: {
        chunkArray(data){
            return data;
        },
        currentAnswer(item){
            return item.correctAnswers.join(',');
        },
        toggleShow() {
            this.questionShow = !this.questionShow;
        },
        checkAnalysis(e) {
            const tar = e.target;
            if (tar.className === 'active') {
                tar.setAttribute('class', '');
                tar.parentNode.parentNode.parentNode.children[1].style.display = 'none';
            } else {
                tar.setAttribute('class', 'active');
                tar.parentNode.parentNode.parentNode.children[1].style.display = 'block';
            }
        },
        hasSelect(question, option){
            console.log(question.correctAnswers)
            if(question.correctAnswers.indexOf(option)>-1){
                return 'is-current';
            }else if(question.answers.indexOf(option)>-1){
                return 'is-error';
            }else{
                return 'option';
            }
            
        },
        hasOptionSelect(question, option){
            if(question.answer){
                if(question.answer.includes(option)){
                    return true;
                }
            }
            return false;
        },
        getAnswer(question){
            return question.answers ? question.answers.join(',') : '';
        },
    },
};
</script>

<style lang="scss" scoped>
    .question {
        .title-box {
            line-height: 50px;
            padding: 0 15px;
            margin-bottom: 2px;
            background: #fff;
            .question-title {
                font-size: 14px;
                color: #454545;
                font-weight: bold;
            }
            .each-score {
                font-size: 14px;
                color: #b3b3b3;
                float: right;
            }
            i {
                font-size: 12px;
                margin: 0 10px;
                color: #808080;
                cursor: pointer;
            }
        }
        .content-box {
            .item-question {
                padding: 0 15px 15px;
                background: #fff;
                margin-bottom: 5px;
                .question-and-options {
                    border-bottom: 1px dotted #d9d9d9;
                    .each-question-title {
                        font-size: 14px;
                        color: #454545;
                        font-weight: bold;
                    }
                    .question-answers {
                        > div {
                            width: 100%;
                        }
                        .options{
                            width: 100%;
                            display: flex;
                        }
                        div.imgList{
                            width: 100%;
                            overflow: hidden;
                            .ir{
                                float: right;
                                width: 50%;
                            }
                            .il{
                                float: left;
                                width: 50%;
                            }
                        }
                        .answer {
                            width: 50%;
                            line-height: 40px;
                            display: inline-block;
                            .option-container{
                                width: 32px;
                                height: 32px;
                                line-height: 32px;
                                text-align: center;
                                border-radius: 50%;
                                display: inline-block;
                                margin-right: 16px;
                                box-sizing: border-box;
                                &.is-current {
                                    background: #71ac24;
                                    color: #fff;
                                }
                                &.is-error {
                                    color: #fff;
                                    background: #f91b1b;
                                }
                                &.option{
                                    border: 1px solid #E1E4E8;
                                    background: #E1E4E8;
                                }
                            }
                            .optionContent{
                                font-size: 14px;
                                color: #454545;
                            }
                        }
                        &.checkbox-question {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 15px;
                            margin-top: 15px;
                            .answer {
                                .option-container{
                                    border-radius: 0;
                                }
                            }
                        }
                        &.radio-question {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 15px;
                            margin-top: 15px;
                        }
                        &.judge-question {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 15px;
                            margin-top: 15px;
                        }
                        &.fill-blanks-question {
                            margin-bottom: 15px;
                            margin-top: 15px;
                            font-size: 14px;
                            color: #454545;
                            line-height: 30px;
                        }
                        &.subjective-question {
                            margin-bottom: 15px;
                            margin-top: 15px;
                            font-size: 14px;
                            color: #454545;
                            line-height: 30px;
                        }
                    }
                }
            }
            .result-box {
                .result {
                    display: flex;
                    justify-content: space-between;
                    line-height: 30px;
                    padding: 10px 0;
                    .current {
                        font-size: 14px;
                        color: #71ac24;
                    }
                    .error {
                        font-size: 14px;
                        color: #f91b1b;
                        display: flex;
                        flex-wrap: wrap;
                        div:first-child {
                            margin-right: 10px;
                        }
                        .current-answer {
                            color: #454545;
                            line-height: 20px;
                            padding: 5px 0;
                        }
                    }
                    .noMarking {
                        font-size: 14px;
                        color: #b3b3b3;
                    }
                    .analysis-btn {
                        span {
                            display: inline-block;
                            width: 70px;
                            height: 26px;
                            line-height: 26px;
                            text-align: center;
                            border-radius: 13px;
                            font-size: 12px;
                            background: #fff;
                            color: #6696ff;
                            border: 1px solid #6696ff;
                            cursor: pointer;
                            &.active {
                                background: #6696ff;
                                color: #fff;
                            }
                        }
                    }
                }
                .analysis {
                    display: none;
                    font-size: 14px;
                    color: #b3b3b3;
                    line-height: 35px;
                }
            }
        }

        .showImg{
            text-align: left;
            padding: 20px 0;
            img{
                max-width: 329px;
                max-height: 190px;
            }
        }
    }
</style>
