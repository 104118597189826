<template>
    <div class="panel">
        <div class="title-box">
            <span class="question-title">
                {{ transform(sort + 1) }}、{{ data.name }}
            </span>
            <i :class="['iconfont', panelShow ? 'safety-icon-uparrow': 'safety-icon-downarrow']" @click="toggleShow" />
            <span class="question-score">共得 {{ data.allScore }} 分</span>
        </div>
        <div v-if="panelShow" class="content-box">
            <span
                v-for="(item, index) in data.list"
                :key="index"
                :class="getClass(item)"
            >
                {{ Number(item.no)+1 }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        sort: {
            type: Number,
            default: () => 0,
        },
        marks: {
            type: Array,
            default: () => [],
        },
        question: {
            type: Object,
            default: () => {},
        },
        examAnswers: {
            type: Object,
            default: () => {},
        },

    },
    data() {
        return {
            panelShow: true,
        };
    },
    methods: {
        toggleShow() {
            this.panelShow = !this.panelShow;
        },
        getClass(item){
            const list = [];
            if(Number(item.is_correct) === 0){
                list.push('error');
            }else if(Number(item.is_correct) === 1){
                list.push('current');
            }
            return list;
        },
        // 数字转换
        transform(tranvalue){
            tranvalue = tranvalue.toString();
            let str = '';
            try{
                let i = 1;
                const dw2 = ['', '万', '亿'];// 大单位
                const dw1 = ['十', '百', '千'];// 小单位
                const dw = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];// 整数部分用
                // 转换整数
                let k1 = 0;// 计小单位
                let k2 = 0;// 计大单位
                let sum = 0;
                const len = tranvalue.length;// 整数的长度
                for(i = 1; i <= len; i++){
                    const n = tranvalue.charAt(len - i);// 取得某个位数上的数字
                    let bn = 0;
                    if(len - i - 1 >= 0){
                        bn = tranvalue.charAt(len - i - 1);// 取得某个位数前一位上的数字
                    }
                    sum += Number(n);
                    if(sum !== 0){
                        str = dw[Number(n)].concat(str);// 取得该数字对应的大写数字，并插入到str字符串的前面
                        if(n === '0'){ sum = 0; }
                    }
                    if(len - i - 1 >= 0){ // 在数字范围内
                        if(k1 !== 3){ // 加小单位
                            if(bn !== 0){
                                str = dw1[k1].concat(str);
                            }
                            k1++;
                        }else{ // 不加小单位，加大单位
                            k1 = 0;
                            const temp = str.charAt(0);
                            // 若大单位前没有数字则舍去大单位
                            if(temp === '万' || temp === '亿'){
                                str = str.substr(1, str.length - 1);
                            }
                            str = dw2[k2].concat(str);
                            sum = 0;
                        }
                    }
                    // 小单位到千则大单位进一
                    if(k1 === 3){
                        k2++;
                    }
                }
            }catch(e){
                return '';
            }
            return str;
        },
    },
};
</script>

<style lang="scss" scoped>
    .panel {
        background: #fff;
        margin-bottom: 1px;
        padding: 0 5px;
        .title-box {
            line-height: 50px;
            .question-title {
                font-size: 14px;
                color: #454545;
                font-weight: bold;
            }
            .question-score {
                font-size: 14px;
                color: #b3b3b3;
                float: right;
            }
            i {
                font-size: 12px;
                float: right;
                margin: 0 10px;
                color: #808080;
                cursor: pointer;
            }
        }
        .content-box {
            font-size: 0;
            padding: 5px 0 15px 0;
            overflow: hidden;
            display: flex;
            flex-flow: wrap;
            span {
                color:#B3B3B3;
                font-size:14px;
                font-weight:400;
                width: 31px;
                height:30px;
                float: left;
                text-align: center;
                line-height: 30px;
                border:1px solid #DBDBDB;
                box-sizing: border-box;
                position: relative;
                cursor: pointer;
                user-select: none;
                &:before{
                    width: calc(100% + 0px);
                    height: calc(100% + 0px);
                    top: -1px;
                    left: -1px;
                    content: ' ';
                    position: absolute;
                    z-index: 9;
                }
                &.active{
                    background: rgba(243,249,255,1);
                    color: #6696FF;
                    &:before{
                        border: 1px solid #DBDBDB;
                    }
                }
                &.now{
                    background: #6696FF;
                    color: #fff;
                    &:before{
                        border: 1px solid #DBDBDB;
                    }
                }
                &.current {
                    color: #fff;
                    background: #71ac24;
                    &:before{
                        border: 1px solid #DBDBDB;
                    }
                }
                &.error {
                    color: #fff;
                    background: #f91b1b;
                    &:before{
                        border: 1px solid #DBDBDB;
                    }
                }
                &.mark:after{
                    top: 0;
                    right: 2px;
                    content: ' ';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #FF0000;
                }
                &.no-status{
                    color: #fff;
                    background: #6696FF;
                }
                &{
                    margin-right: -1px;
                }
                &:nth-child(n+12){
                    margin-top: -1px;
                }
            }
        }
    }
</style>
