<template>
    <div class="result">
        <div class="header">{{examInfo.name}}</div>
        <div class="content">
            <div class="info">
                <!-- <div class="total">
                    <div class="mgc">
                        <span class="score">
                            <span>{{  }}</span>
                            <span class="unit">%</span>
                        </span>
                    </div>
                </div> -->
                <div class="detail task-detail">
                    <div>
                        <span>总分:</span>
                        <span><span class="blue">{{examInfo.total_score}}</span>分</span>
                    </div>
                    <div>
                        <span>通过线:</span>
                        <span><span class="blue">{{examInfo.pass_line}}</span>分</span>
                    </div>
                    <div v-if="examInfo.score!==''">
                        <span>得分:</span>
                        <span><span class="blue">{{examInfo.score}}</span>分</span>
                    </div>
                </div>
            </div>
            <div class="handle">
                <span v-if="examInfo.score!==''" class="active" @click="changeStatus('detail')">查看详情</span>
                <span v-if="examInfo.score===''" class="active" @click="changeStatus('exam')">开始考试</span>
            </div>
        </div>
    </div>
</template>
<script>
// import { MessageBox } from 'element-ui';
import { mapState } from 'vuex';


export default {
    data(){
        return {
        };
    },
    computed:mapState([
        'examInfo',
    ]),
    mounted() {
        
    },
    methods: {
        changeStatus(mes){
            this.$emit('changeStatus',mes);
        }
    },
};
</script>
<style lang="scss" scoped>
.result{
    background: url('../assets/bg.jpg') center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    .header{
        padding-top: 90px;
        text-align: center;
        color: #FFFFFF;
        font-size: 36px;
    }
    .content{
        position: absolute;
        left: 50%;
        top: 55%;
        width: 860px;
        height: 450px;
        background: #fff;
        font-size: 18px;
        margin-left: -430px;
        margin-top: -225px;
        border-radius: 10px;
        padding: 56px 100px 35px;
        box-sizing: border-box;
        .info{
            overflow: hidden;
            .total{
                text-align: center;
                float: left;
                .mgc{
                    width: 260px;
                    height: 260px;
                    position: relative;
                    img{
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                    }
                    .score{
                        position: absolute;
                        font-size: 46px;
                        top: 0px;
                        left: 0;
                        text-align: center;
                        width: 100%;
                        height: 260px;
                        line-height: 260px;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: baseline;
                        .unit{
                            font-size: 28px;
                        }
                    }
                }
                .tz{
                    font-size: 20px;
                    font-weight: bold;
                }
                .qualified{
                    color: #6696FF;
                }
                .unqualified{
                    color: #B3B3B3;
                }
                .wait{
                    color: #808080;
                }
            }
            .detail{
                margin: auto;
                width: 169px;
                padding-top: 60px;
                & > div{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #595959;
                    padding: 0 20px;
                    border-left: 4px solid #6696FF;
                    margin-bottom:  31px;
                }
                &.task-detail{
                    padding-top: 90px;
                }
            }
        }
        .handle{
            position: absolute;
            bottom: 35px;
            left: 0;
            text-align: center;
            width: 100%;
            span{
                width: 150px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border: 1px solid #6696FF;
                box-sizing: border-box;
                display: inline-block;
                color: #6696FF;
                cursor: pointer;
                border-radius: 20px;
                &:nth-child(n+2){
                    margin-left: 20px;
                }
                &.active{
                    background: #6696FF;
                    color: #fff;
                }
            }
        }
    }
    .footer{
        font-size: 14px;
        color: #FFFFFF;
        position: absolute;
        left: 50%;
        bottom: 60px;
        margin-left: -119px;
    }
    .blue{
        color: #6696FF;
    }
}
@media(min-width: 1900px){
    .result{
        .content{
            width: 1100px;
            height: 580px;
            margin-left: -550px;
            margin-top: -290px;
            padding: 88px 160px 35px;
            .info{
                .detail{
                    width: 40%;
                }
            }
            .handle{
                bottom: 60px;
            }
        }
    }
}
@media  screen and (max-height: 720px) {
    .result{
        .header{
            padding-top: 40px;
        }
    }
}
</style>
