<template>
    <div v-if="Object.hasOwnProperty.call(question,'type')" class="right_content">
        <div :class="['quesList']">
            <div class="ques">
                <div class="quesContent">
                    <div class="typeInfoTip">
                        <span
                            class="infoTitle"
                        >{{ (question.tindex+1).toString()|transform }}、{{ question.type | formatQuestionType }}</span>
                        <span>每题{{ Number(question.score) }}分</span>
                    </div>
                    <div class="quesdom">
                        <div class="bt">
                            <div>
                                <span>{{ question.no+1 }}、{{ question.name }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                v-if="[1,3].indexOf(Number(question.type)+1) > -1"
                                :class="['choice', 'judge']"
                            >
                                <div v-for="(opt,y) in question.options" :key="'sds_'+y">
                                    <span>
                                        <span
                                            :class="[answers.indexOf(opt.option) > -1 ? 'optionBox':'option']"
                                            @click="answerQ(opt.option)"
                                        >
                                            {{ opt.option }}
                                        </span>
                                    </span>
                                    <span>{{ opt.option_content }}</span>
                                </div>
                            </div>
                            <div
                                v-if="[2].indexOf(Number(question.type)+1)>-1"
                                :class="['choice','more']"
                            >
                                <div v-for="(opt,y) in question.options" :key="'sds_'+y">
                                    <span>
                                        <span
                                            :class="[answers.indexOf(opt.option)>-1 ? 'optionBox':'option']"
                                            @click="answerQ(opt.option)"
                                        >
                                            {{ opt.option }}
                                        </span>
                                    </span>
                                    <span>{{ opt.option_content }}</span>
                                </div>
                            </div>
                            <div v-if="[4].indexOf(Number(question.type)+1)>-1" :class="['fill']">
                                <div class="completion">
                                    <span>答案：</span>
                                    <div class="aws">
                                        <input
                                            v-for="(optz,j) in question.options"
                                            :key="'optz_'+j"
                                            v-model="completion[j]"
                                            :placeholder="`共${optz.answer_content.trim().length}个字`"
                                            :disabled="question.isMarking"
                                            maxlength="100"
                                            @input="answerQ"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="[5].indexOf(Number(question.type)+1)>-1" :class="['fill']">
                                <div class="completion">
                                    <span>答案1：</span>
                                    <div class="aws">
                                        <textarea
                                            v-model="describe"
                                            :disabled="question.isMarking"
                                            maxlength="800"
                                            onpaste="return false;"
                                            placeholder="不支持粘贴功能"
                                            @input="answerQ"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="question.isCurrent === '1'" class="resultBox">
                            <div class="result">
                                <div class="current">
                                    <div>回答正确!</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="question.isCurrent === '2'" class="resultBox">
                            <div class="result">
                                <div class="error">
                                    <div>回答错误!</div>
                                    <div
                                        class="current-answer"
                                    >
                                        正确答案：{{ question.currentAnswer ? question.currentAnswer.join(',') : '' }}
                                    </div>
                                </div>
                            </div>
                            <div class="analysis">解析：{{ question.itemAnalysis }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="handleColumn">
            <span v-if="question.no > 0" :class="[question.no > 0 ? 'can' : '','prev']" @click="selectQuestion(Number(question.no)-1)">上一题</span>
            <span
                class="mark"
                @click="markQuestion"
            >{{ marks.indexOf(question.id)>-1 ? '已标记' : '标记该题' }}</span>
            <span v-if="question.next" class="next" @click="selectQuestion(Number(question.no)+1)">下一题</span>
            <span v-if="!question.next" class="submit" @click="submit">交卷</span>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { trim } from 'lodash';
export default {
    components:{
    },
    data() {
        return {
            describe:'',
            completion: [],
        }
    },
    computed:{
        ...mapState({
            questionsInfo:(state) => state.questionsInfo,
            question:(state) => state.question,
            examAnswers:(state) => state.answers,
            marks:(state) => state.marks,
            answers:(state) => {
                let arr = [];
                const id = state.question.id;
                if(Object.hasOwnProperty.call(state.answers,id)){
                    arr = state.answers[id];
                }
                console.log('答案',arr);
                return arr;
            }
        })
    },
    mounted() {
        console.log(this.question);
    },
    methods: {
        changeState({type, id, answer}){
            console.log('changeState',answer);
            if(type === 'recordExamAnswer'){
                const answers = JSON.parse(JSON.stringify(this.examAnswers));
                answers[id] = answer;
                console.log(answers);
                this.$store.commit('CHANGE_STATE',{answers});
            }
        },
        // 记录答案
        answerQ(value) {
            console.log('answerQ',value);
            switch (Number(this.question.type)+1) {
                case 1:
                case 3:
                    this.changeState({
                        type: 'recordExamAnswer',
                        id: this.question.id,
                        answer: [value],
                    });
                    break;
                case 2: {
                    let answer = JSON.parse(JSON.stringify(this.answers));
                    if (this.answers.indexOf(value) > -1) {
                        answer = this.answers.filter(item => item !== value);
                    } else {
                        answer.push(value);
                    }
                    answer.sort();
                    this.changeState({
                        type: 'recordExamAnswer',
                        id: this.question.id,
                        answer,
                    });
                    break;
                }
                case 4: {
                    const aws = [];
                    // 判断填空题是否答题
                    let emptyFlag = false;
                    for (let i = 0; i < this.question.options.length; i++) {
                        if (this.completion[i] !== '') {
                            aws[i] = this.completion[i].replace(/\s+/g, '');
                        } else {
                            aws[i] = '';
                        }
                        if (aws[i] !== ''){
                            emptyFlag = true;
                        }
                    }
                    this.changeState({
                        type: 'recordExamAnswer',
                        id: this.question.id,
                        answer: emptyFlag ? aws : [],
                    });
                    break;
                }
                case 5:
                    this.describe = this.describe.replace('\'', '');
                    this.describe = this.describe.replace('"', '');
                    if (trim(this.describe)) {
                        this.changeState({
                            type: 'recordExamAnswer',
                            id: this.question.id,
                            answer: [this.describe],
                        });
                    } else {
                        this.changeState({
                            type: 'recordExamAnswer',
                            id: this.question.id,
                            answer: [],
                        });
                    }
                    break;
                default: break;
            }
            return true;
        },
        selectQuestion(no){
            console.log('selectQuestion', this.questionsInfo);
            for(let i = 0; i < this.questionsInfo.length; i++){
                const item = this.questionsInfo[i];
                for(let j = 0; j < item.list.length; j++){
                    if(Number(item.list[j].no) === no){
                        console.log(no);
                        this.$store.commit('CHANGE_STATE',{question:item.list[j]});
                        return false;
                    }
                }
            }
        },
        markQuestion(){
            const id = this.question.id;
            let marks = JSON.parse(JSON.stringify(this.marks));
            if(marks.indexOf(id) > -1){
                marks.splice(marks.indexOf(id),1);
            }else{
                marks.push(id);
            }
            this.$store.commit('CHANGE_STATE',{marks});

        },
        submit(){
            const that = this;
            this.$msgbox.confirm('交卷后无法继续考试，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$store.dispatch('SUBMIT_PAPER',that.$http).then(res => {
                    if(res){
                        that.$toast('试卷提交成功');
                        const tem = setTimeout(() => {
                            location.reload();
                            clearTimeout(tem);
                        }, 1500);
                    }
                })
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.right_content{
    width:calc(100% - 360px);
    height:100%;
    position: relative;
    background: #fff;
}
.quesList {
    overflow-y: auto;
    height: 100%;
    .ques {
        .quesContent {
            font-size: 14px;
            color: #454545;
            .typeInfoTip {
                padding: 0 20px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #f7f9fa;
                span {
                    color: #b3b3b3;
                    &.infoTitle {
                        color: #595959;
                        font-weight: bold;
                    }
                }
            }
            .quesdom {
                padding: 50px;
                .bt {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 70px;
                    div:first-child {
                        overflow: hidden;
                        padding: 2px 0;
                        line-height: 30px;
                        span {
                            font-weight: bold;
                            color: #454545;
                            font-size: 20px;
                        }
                    }
                }
                .resultBox {
                    padding-top: 20px;
                    border-top: 1px dotted #d9d9d9;
                    .result {
                        display: flex;
                        justify-content: space-between;
                        line-height: 30px;
                        padding: 10px 0;
                        .current {
                            font-size: 14px;
                            color: #71ac24;
                        }
                        .error {
                            font-size: 14px;
                            color: #f91b1b;
                            display: flex;
                            flex-wrap: wrap;
                            div:first-child {
                                margin-right: 10px;
                            }
                            .current-answer {
                                color: #454545;
                                line-height: 20px;
                                padding: 5px 0;
                            }
                        }
                    }
                    .analysis {
                        font-size: 14px;
                        color: #b3b3b3;
                        line-height: 35px;
                    }
                }
                .error {
                    color: #f91b1b;
                }
                .current {
                    color: #71ac24;
                }
                i {
                    font-size: 18px;
                }
            }
            .choice {
                overflow: hidden;
                div {
                    padding: 12px 0 5px;
                    overflow: hidden;
                    font-size: 18px;
                    font-weight: 400;
                    padding-bottom: 30px;
                    color: #454545;
                    line-height: 20px;
                    & > * {
                        float: unset;
                    }
                    span:first-child {
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        border-radius: 50%;
                        display: inline-block;
                        box-sizing: border-box;
                        margin-right: 22px;
                        text-align: center;
                    }
                    .option{
                            border: 1px solid #E1E4E8;
                            background: #E1E4E8;
                            cursor: pointer;
                    }
                    .optionBox {
                        background: #4680FF;
                        color: #fff;
                        border: 1px solid #dbdbdb;
                    }
                    .el-icon-circle-check {
                        color: #4c85ff;
                        display: inline-block;
                        font-size: 18px;
                        margin-right: 22px;
                    }
                    .errorOption{
                        color: #fff;
                        background: #f91b1b;
                    }
                    .rightOption{
                        background: #71ac24;
                        color: #fff;
                    }
                }
            }
            .judge {
                div {
                    width: 100%;
                }
            }
            .more {
                div {
                    span:first-child {
                        border-radius: 0;
                    }
                    i {
                        color: #fff;
                        background: #4c85ff;
                        display: inline-block;
                        font-size: 18px;
                        margin-right: 22px;
                        &.current {
                            background: #71ac24;
                            color: #fff;
                        }
                        &.error {
                            background: #f91b1b;
                            color: #fff;
                        }
                    }
                }
            }
            .fill {
                padding-bottom: 20px;
                .completion {
                    overflow: hidden;
                    span {
                        float: left;
                        color: #454545;
                        height: 40px;
                        line-height: 40px;
                    }
                    .aws {
                        float: left;
                        width: calc(100% - 45px);
                        input {
                            width: 100%;
                            padding: 5px;
                            box-sizing: border-box;
                            height: 40px;
                            border: none;
                            border: 1px solid #dbdbdb;
                            font-size: 14px;
                            &:focus {
                                outline: none;
                            }
                            &:nth-child(n + 2) {
                                margin-top: 5px;
                            }
                        }
                        textarea {
                            width: 100%;
                            padding: 10px;
                            box-sizing: border-box;
                            margin-top: 5px;
                            border: 1px dotted #dbdbdb;
                            height: 300px;
                            font-size: 14px;
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .quesR {
        padding: 0 15px 50px;
        div:first-child {
            color: #595959;
            font-size: 14px;
            font-weight: bold;
            padding: 23px 0;
        }
    }
    .quesRow {
        display: flex;
        align-items: center;
        height: 50px;
        span {
            width: 25%;
            text-align: center;
            font-size: 14px;
            color: #454545;
        }
        &:first-child {
            background: #f4f8fb;
            height: 40px;
            span {
                color: #7f8fa4;
            }
        }
        &:nth-child(n + 2) {
            border-top: 1px solid #ebeef5;
            span {
                border-right: 1px solid #ebeef5;
                height: 50px;
                line-height: 50px;
                &:last-child {
                    border-right: none;
                }
                input {
                    padding: 5px;
                    border: 1px solid #ebeef5;
                    max-width: 135px;
                }
            }
        }
        &:last-child {
            border-bottom: 1px solid #ebeef5;
        }
    }
}


.handleColumn{
                padding: 18px 0;
                text-align: center;
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                background: #fff;
                span{
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    display: inline-block;
                    border-radius:2px;
                    color:#fff;
                    margin-right: 15px;
                    cursor: pointer;
                    user-select: none;
                    &:last-child{
                        margin: 0;
                    }
                    &.prev{
                        background: #6696FF;
                    }
                    &.sbtn{
                        background: #C3CCDF;
                    }
                    &.mark{
                        background: #FF7F7F;
                    }
                    &.next{
                        background: #6696FF;
                    }
                    &.submit{
                        background: #6696FF;
                    }
                }
            }
</style>
