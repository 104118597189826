<template>
    <div v-if="Object.hasOwnProperty.call(examInfo,'name')" class="exam_header">
        <span class="title">{{examInfo.name}}</span>
        <span class="total_score">考试总分：{{examInfo.total_score}}分</span>
        <span v-if="examInfo.score!==''" class="score">得分：{{examInfo.score}}分</span>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState([
        'examInfo'
    ]),
    props:{
        obj:{
            type: Object,
            defalut: () => {},
        }
    },
    mounted() {
        console.log(this.examInfo);
    },
}
</script>
<style lang="scss" scoped>
.exam_header{
    height:56px;
    line-height: 55px;
}
.title{
    color: #333;
    font-size: 18px;
    font-weight: 700;
    padding-left: 15px;
    margin-right: 40px;
}
.total_score{
    color: grey;
    font-size: 14px;
    font-weight: 500;
    margin-right: 40px;
}
.score{
    color: grey;
    font-size: 15px;
    font-weight: 500;
}
</style>
