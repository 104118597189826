<template>
    <div v-loading="load" style="width:100%;" :style="{height:innerHeight+'px'}">
        <div v-if="noToken">
            <Header/>
            <Footer/>
        </div>
        <Result v-if="status===''&&noToken===false" @changeStatus="changeStatus"/>
        <Exam v-if="status==='exam'&&noToken===false"/>
        <Detail v-if="status==='detail'&&noToken===false"/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Result from './result'
import Exam from './liveExam'
import Detail from './detail/detail'
export default {
    computed:mapState([
        'examType',
    ]),
    components:{
        Exam,
        Result,
        Detail
    },
    data() {
        return {
            innerHeight:'',
            load:false,
            status:'',
            noToken:false,
        }
    },
    async mounted() {
        const queryUserId = this.$route.query.userId||'';
        // if(localStorage.getItem('shouguancloud_token')||queryUserId){
        //     this.noToken = false;
        // }else{
        //     this.noToken = true;
        //     return false;
        // }
        if(this.$route.query.type&&Number(this.$route.query.type) === 0){
            this.$store.commit('CHANGE_STATE',{examType:0});
        }
        this.load = true;
        this.innerHeight = window.innerHeight;
        const res = await this.$http.get('/api/exam/getExamPaper',{exam_type:this.examType,userId:queryUserId});
        if(res){
            this.$store.commit('CHANGE_STATE',{examInfo:res});
            let count = 0;
            const answers = {};
            res.questions.forEach((item,index) => {
                res.questions[index].allScore = 0;
                item.list.forEach((subItme,subIndex) => {
                    res.questions[index].allScore += Number(res.questions[index].list[subIndex].get_score);
                    res.questions[index].list[subIndex].no = count;
                    res.questions[index].list[subIndex].tindex = index;
                    res.questions[index].list[subIndex].next = 1;
                    const temQuestion = this.formatCorrectAnswers(res.questions[index].list[subIndex])
                    res.questions[index].list[subIndex] = JSON.parse(JSON.stringify(temQuestion));
                    answers[subItme.id] = [];
                    count++;
                })
            })
            // 最后一题
            const index = res.questions.length - 1;
            const childIndex = res.questions[index].list.length - 1;
            res.questions[index].list[childIndex].next = 0;
            this.$store.commit('CHANGE_STATE',{question:JSON.parse(JSON.stringify(res.questions[0].list[0]))});
            this.$store.commit('CHANGE_STATE',{questionsInfo:res.questions});
            this.$store.commit('CHANGE_STATE',{answers});
            this.load = false;
        }
    },
    methods: {
        changeStatus(mes){
            this.status = mes;
        },
        formatCorrectAnswers(question){
            question.correctAnswers = [];
            const type = Number(question.type)+1;
            if([1,2,3].indexOf(type)>-1){
                question.options.forEach(item => {
                    Number(item.is_answer)===1&&question.correctAnswers.push(item.option);
                })
            }
            if([4,5].indexOf(type)>-1){
                question.options.forEach(item => {
                    question.correctAnswers.push(item.answer_content);
                })
            }
            return question;
        }
    },
}
</script>
<style lang="scss" scoped>
.content{
    background: #f7f9fa;
    padding: 15px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
</style>
