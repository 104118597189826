<template>
    <div style="width:100%;" :style="{height:innerHeight+'px'}">
        <ExamHeader/>
        <div class="content" :style="{height:`${innerHeight- 56}px`}">
            <LeftPanel/>
            <RightPanel/>
        </div>        
    </div>
</template>
<script>
import ExamHeader from './components/header'
import LeftPanel from './components/leftPanel'
import RightPanel from './components/rightPanel'
export default {
    components:{
        ExamHeader,
        LeftPanel,
        RightPanel,
    },
    data() {
        return {
            innerHeight:'',
        }
    },
}
</script>
<style lang="scss" scoped>
.content{
    background: #f7f9fa;
    padding: 15px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
</style>
