<template>
    <div class="left_content">
        <div class="studentInfo">
            <div>
                <span>考生姓名</span>
                 <span>{{userInfo.username}}</span>
            </div> 
        </div>

        <div v-if="questionsInfo.length>0" class="question_info">
            <OverViewPanel 
                v-for="(item, index) in questionsInfo" 
                :key="'tyoeinfi_'+index"
                :sort="index"
                :question="question"
                :data="item"
                :marks="marks"
                :examAnswers="answers"
                @answer="answer"
            />
        </div>
    </div>
</template>
<script>
import OverViewPanel from './overview';
import { mapState } from 'vuex'
export default {
    components:{
        OverViewPanel
    },
    computed: mapState([
        'questionsInfo',
        'question',
        'answers',
        'marks',
        'userInfo'
    ]),
    methods: {
        answer(data){
            console.log('answer',data);
        },
    },
}
</script>
<style lang="scss" scoped>
.question_info{
    height:calc(100% - 70px);
    overflow-y: auto;
    .panel{
        background: #fff;
        margin-bottom: 1px;
        padding: 0 5px;
    }
}


div{
    box-sizing: border-box;
}
.submit{
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff !important;
    margin: 0;
    background: #6696ff;
    display: inline-block;
    width: 100%;
    border-radius: 15px;
    cursor: pointer;
}
.left_content{
    width:350px;
    height:100%;
    background: #fff;
}
.studentInfo{
    background: #fff;
    border-bottom: 5px solid #f7f9fa;
    padding: 20px 15px;
    height:70px;
    div{
        padding-bottom: 20px;
        span:first-child{
            font-weight: 700;
            color: #595959;
            margin-right: 10px;
            display: inline-block;
        }
    }
}
</style>
