var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',{staticClass:"title-box"},[_c('span',{staticClass:"question-title"},[_vm._v(" "+_vm._s(_vm.sort === 0 ? '一、' : ( _vm.sort === 1 ? '二、' : ( _vm.sort === 2 ? '三、' : ( _vm.sort === 3 ? '四、' : ( _vm.sort === 4 ? '五、' : '' )))))+" "+_vm._s(_vm._f("formatQuestionType")(_vm.data.list[0].type))+"（"+_vm._s(_vm.data.list.length)+"） ")]),_c('i',{class:['iconfont', _vm.questionShow ? 'safety-icon-arrow_up': 'safety-icon-arrow_down'],on:{"click":_vm.toggleShow}}),_c('span',{staticClass:"each-score"},[_vm._v("每题"+_vm._s(Number(_vm.data.list[0].score))+"分")])]),(_vm.questionShow)?_c('div',{staticClass:"content-box"},_vm._l((_vm.data.list),function(item,index){return _c('div',{key:index,staticClass:"item-question"},[_c('div',{staticClass:"question-and-options"},[_c('div',{staticClass:"each-question-title"},[_vm._v(" "+_vm._s(index + 1)+"."+_vm._s(item.name)+" ")]),(Number(item.type)+1 === 2)?_c('div',{staticClass:"checkbox-question question-answers"},_vm._l((_vm.chunkArray(item.options)),function(tmps,key){return _c('div',{key:key},[_c('div',{staticClass:"options"},[_c('div',{staticClass:"answer"},[_c('span',{class:['option-container',
                                               _vm.hasSelect(item,tmps.option)]},[_vm._v(_vm._s(tmps.option))]),_c('span',{staticClass:"optionContent"},[_vm._v(_vm._s(tmps.option_content))])])])])}),0):(Number(item.type)+1 === 1 || Number(item.type)+1 === 3)?_c('div',{class:[
                            'question-answers',
                            { 'radio-question': Number(item.type)+1 === 1 },
                            { 'judge-question': Number(item.type)+1 === 3 },
                        ]},_vm._l((_vm.chunkArray(item.options)),function(tmps,key){return _c('div',{key:key},[_c('div',{staticClass:"options"},[_c('div',{staticClass:"answer"},[_c('span',{class:['option-container',
                                               _vm.hasSelect(item,tmps.option)]},[_vm._v(_vm._s(tmps.option))]),_c('span',{staticClass:"optionContent"},[_vm._v(_vm._s(tmps.option_content))])])])])}),0):(Number(item.type)+1 === 4 || Number(item.type)+1 === 5)?_c('div',{class:[
                            'question-answers',
                            { 'fill-blanks-question': Number(item.type)+1 === 4 },
                            { 'subjective-question': Number(item.type)+1 === 5 }
                        ]},[_vm._v(" 答："+_vm._s(_vm.getAnswer(item))+" ")]):_vm._e()]),_c('div',{staticClass:"result-box"},[_c('div',{staticClass:"result"},[(Number(item.is_correct) === 1)?_c('div',{staticClass:"current"},[_vm._v(" 回答正确! ")]):(Number(item.is_correct) !== 1)?_c('div',{staticClass:"error"},[_c('div',[_vm._v("回答错误!")]),_c('div',{staticClass:"current-answer"},[_vm._v(" 正确答案："+_vm._s(_vm.currentAnswer(item))+" ")])]):_vm._e()])])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }