<template>
    <div style="width:100%;" :style="{height:innerHeight+'px'}">
        <ExamHeader/>
        <div class="content" :style="{height:`${innerHeight- 56}px`}">
            <LeftPanel/>
            <RightPanel/>
        </div>
    </div>
</template>
<script>
import ExamHeader from '../components/header'
import LeftPanel from './left'
import RightPanel from './right'
export default {
    components:{
        ExamHeader,
        LeftPanel,
        RightPanel,
    },
    data() {
        return {
            innerHeight:'',
            load:false,
        }
    },
    async mounted() {
        this.innerHeight = window.innerHeight;
    },
}
</script>
<style lang="scss" scoped>
.content{
    background: #f7f9fa;
    padding: 15px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
</style>
